// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Dropzone
@import "node_modules/dropzone/src/dropzone";

.dropzone {
  border: 2px dashed #0087F7;
  border-radius: 5px;
  background: white;
}

.mail-sent-0 {
  color: #cccccc;
}

.mail-sent-1 {
  color: #008000;
}
